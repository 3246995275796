<template>
    <div class="">
      <!-- v-if="getNavbarData.userType == 'shopify'" -->
      <Modal
        ref="ExportModal"
        id="ExportModal"
        title="<span class='mr-2'>Export customer leads.</span>"
        size="md">
        <div class="mx-2">
          <div class="">
            <p class="m-0 font-weight-bold">Export</p>
            <b-form-radio
              name="export-category"
              v-model="category"
              value="all"
              class="radio-button my-2">
              All Leads
            </b-form-radio>
            <b-form-radio
            :disabled="!(exportProductFilter.start_date||exportProductFilter.end_date||exportProductFilter.title||exportProductFilter.quizIds.length>0)"
              name="export-category"
              v-model="category"
              value="filtered"
              class="radio-button my-2">
              Filtered Leads
            </b-form-radio>
            <b-form-radio
              name="export-category"
              v-model="category"
              :disabled="checkProducts"
              value="selected"
              class="radio-button my-2">
              Selected Leads
            </b-form-radio>
          </div>
          <p
            style="
              color: var(--Gray-500, #667085);
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            "
          >
            Customer leads and their detailed data will be provided in a CSV
            file.
          </p>
          <div class="d-flex justify-content-center">
            <Button :is-loading="isFileDownload" @click="$emit('export', {category,insight})">Export</Button>
          </div>
        </div>
      </Modal>
    </div>
  </template>
  <script>
  // import Modal from "../Layout/Modal.vue";
  import Modal from "../Layout/Modal.vue";
  // import Modal from "../../Layout/Modal.vue";
  import Button from "../Layout/Button.vue";
  export default {
    props:{
      isFileDownload:Boolean,
      selected:Number,
       exportProductFilter: {
        type: Object,
        default: () => ({
           title: "",
          quizIds: [],
          start_date: "",
          end_date: "",
        })
      }
    
    },
    components: {
      Modal,
      Button,
    },
  
    data() {
      return {
        category: "all",
        insight:'quiz'
      };
    },
    computed: {
      checkProducts(){
  
      
        return this.selected<=0
    
      },
    },
    methods: {
      toast(text) {
        this.$toasted.show(text, {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      },
      openExportLeadsModal() {
        this.category="all"
        this.$refs.ExportModal.open();
      },
      closeShopifyProductModal() {
        this.$refs.ExportModal.close();
      },
    },
  };
  </script>
  <style scoped>
  .radio-button
    >>> .custom-control-input:checked
    ~ .custom-control-label::before {
    background: #ffa201;
    border-color: #ffa201;
  }
  .radio-button >>> .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem #ffa201;
  }
  </style>